<!-- 用户金币记录 -->
<template>
  <div class="ucenter">
    <el-row>
      <el-col :span="4" class="sider">
        <div
          style="
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-weight: bold;
            color: #ff414d;
          "
        >
          会员首页
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              <div class="collapse-item__title">交易中心</div>
            </template>
            <ul class="collapse-item__ul">
              <li
                v-for="(item, k) in tradeMenu"
                :key="k"
                @click="changeUrl(item)"
              >
                <span v-if="item.id === activeId" style="color: #ff414d">
                  {{ item.title }}
                </span>
                <span v-else>
                  {{ item.title }}
                </span>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="20">
        <el-form v-model="query">
          <el-row :gutter="20">
            <el-col :span="6">&nbsp;</el-col>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="query.order_no" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="类型">
                <el-select v-model="query.type">
                  <el-option value="0" label="全部">全部</el-option>
                  <el-option value="1" label="支出">支出</el-option>
                  <el-option value="2" label="收入">收入</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-button
                type="primary"
                size="large"
                @click="queryData"
                style="position: relative; top: 40px; width: 100px;"
                >查询</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col
        :span="20"
        style="padding: 0 20px"
        v-loading="loading"
        element-loading-text="加载中"
      >
        <!-- 我的金币记录 -->
        <div class="goldList" v-if="goldList.length > 0">
          <el-row class="listView">
            <el-col :span="6">订单号</el-col>
            <el-col :span="6">金币</el-col>
            <el-col :span="6">备注</el-col>
            <el-col :span="6">日期</el-col>
          </el-row>
          <el-row
            class="listView"
            v-for="(item, index) in goldList"
            :key="index"
          >
            <el-col :span="6">{{ item.order_no }}</el-col>
            <el-col
              :span="6"
              v-if="item.gold < 0"
              style="color: #f5222d; font-weight: bold"
              >{{ item.gold }}</el-col
            >
            <el-col :span="6" v-else style="color: gold; font-weight: bold"
              >+{{ item.gold }}</el-col
            >
            <el-col :span="6">{{ item.remark }}</el-col>
            <el-col :span="6">{{ item.created_at }}</el-col>
          </el-row>

          <!-- 底部分页 -->
          <div style="text-align: center">
            <!-- 分页 -->
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="query.pageSize"
              :total="totalCount"
              @current-change="currentChange"
            ></el-pagination>
            <!-- 分页END -->
          </div>
        </div>
        <!-- 我的金币记录END -->

        <!-- 列表为空的时候显示的内容 -->
        <div v-else class="listEmpty">
          <el-row style="text-align: center"> 您的金币记录还是空的！ </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getUcenterCollapseItems } from "@/util/common";

export default {
  name: "Gold",
  data() {
    return {
      activeName: "1",
      activeId: 2,
      goldList: [],
      tradeMenu: [],
      loading: true,
      total: [],
      totalCount: 0,
      currentPage: 1, //当前页码
      query: {
        page: 1,
        pageSize: 10,
        uid: 0,
        order_no: "",
        type: "0",
      },
    };
  },
  created() {
    this.tradeMenu = getUcenterCollapseItems();
    this.query.uid = this.$store.getters.getUser.id;
    this.getGoldList();
  },
  mounted() {},
  methods: {
    /**
     * 切换链接
     * @param {*} item
     */
    changeUrl(item) {
      if (item.url) {
        this.activeId = item.id;
        this.$router.push(item.url);
      }
    },
    /**
     * 查询金币
     */
    queryData() {
      this.query.page = 1;
      this.getGoldList();
    },
    /**
     * 异步获取用户金币列表
     */
    async getGoldList() {
      this.loading = true;
      const { data } = await this.$axios.post(
        this.$apiBaseUrl + "/web/gold/list",
        this.query
      );
      this.goldList = data.data.data;
      this.totalCount = data.data.total;
      this.loading = false;
    },
    /**
     * 变更页码
     * @param {*} currentPage
     */
    currentChange(currentPage) {
      this.loading = true;
      this.query.page = currentPage;
      this.getGoldList();
    },
  },
};
</script>

<style scoped>
.ucenter {
  width: 1225px;
  font-size: 14px;
  margin: 0 auto;
}
.collapse-item__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
}
.collapse-item__ul {
  padding: 5px 0 0 0;
}
.collapse-item__ul li {
  color: #757578;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 1em;
  cursor: pointer;
}

.listView {
  border-bottom: 1px solid #ff6700;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
}
</style>
