/**
 * 获取会员中心左侧菜单
 */
export function getUcenterCollapseItems() {
  return [
    {
      id: 1,
      title: "我的订单",
      url: "/order",
    },
    {
      id: 2,
      title: "我的金币",
      url: "/gold",
    },
    // {
    //   id: 3,
    //   title: "我的售后",
    //   url: "",
    // },
  ];
}

/**
 * 计算日期间隔年数
 * @param {*} start
 * @param {*} end
 * @returns
 */
export function getYearsBetween(start, end = "") {
  let endDate = 0;
  if (!end) endDate = new Date();
  else endDate = Date.parse(end);
  const startDate = Date.parse(start);
  if (startDate >= endDate) {
    return 1;
  } else {
    const days = (endDate - startDate) / (24 * 3600 * 1000);
    return days > 365 ? Math.ceil(days / 365) : 1;
  }
}

/**
 * 计算总价
 * @param {*} goods_total 
 * @param {*} express_fee 
 * @param {*} daifa_fee 
 * @param {*} check_fee 
 * @param {*} package_fee 
 * @param {*} brand_fee 
 * @param {*} wishcard_fee 
 * @returns 
 */
export function getTotalPrice(
  goods_total,
  express_fee,
  daifa_fee,
  check_fee,
  package_fee,
  brand_fee,
  wishcard_fee
) {
  const total =
    parseFloat(goods_total) +
    parseFloat(express_fee) +
    parseFloat(daifa_fee) +
    parseFloat(check_fee) +
    parseFloat(package_fee) +
    parseFloat(brand_fee) +
    parseFloat(wishcard_fee);
  return parseFloat(total).toFixed(2);
}
